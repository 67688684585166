import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';
import useSiteMetadata from '../hooks/useSiteMetadata';

interface NavigationProps {
    mobile?: boolean
    onCloseMenu?: () => void
}
const Navigation = ({ mobile, onCloseMenu }: NavigationProps): JSX.Element => {
    const meta = useSiteMetadata();
    const { bookingUrl, email } = meta || {};
    const links = [
        {
            label: 'Home',
            url: '/',
        },
        {
            label: 'Shopify',
            url: '/shopify-agentur-koeln/',
        },
        {
            label: 'Shopify Plus',
            url: '/shopify-plus-agentur/',
        },
        {
            label: 'Migration',
            url: '/shopify-migration/',
        },
        {
            label: 'Headless',
            url: '/headless-shopify/',
        },
        {
            label: 'Kontakt',
            url: '/kontakt/',
        },
    ];

    return (
        <div
            className={classNames(
                'flex flex-col h-full justify-between pt-10 pb-10',
                {
                    'container max-w-unset': mobile,
                    'lg:px-[2.5vw] border-l border-gray-300': !mobile,
                },
            )}
            css={!mobile ? {
                '[aria-hidden="true"] &': {
                    display: 'none',
                },
            } : {}}
        >
            <div>
                <nav
                    className={classNames(
                        'flex flex-col space-y-4 lg:space-y-2 xxl:space-y-3',
                        {
                            'mt-[80px]': mobile,
                        },
                    )}
                >
                    {links.map(({ label, url }) => (
                        <Link
                            className="font-menu leading-none select-none text-gray-600 hover:text-blackLight transition duration-200"
                            to={url}
                            onClick={onCloseMenu}
                            css={{
                                '&[aria-current="page"]': {
                                    color: '#1e1d1c',
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '2px',
                                },
                            }}
                        >
                            {label}
                        </Link>
                    ))}
                </nav>
            </div>
            <div>
                <span className="block text-sm xl:text-base text-blackLight mb-4">
                    E-Mail schreiben:
                    <br />
                    <a
                        href={`mailto:${email || ''}`}
                        className="underline underline-offset-2"
                    >
                        {email}
                    </a>
                </span>
                <span className="block text-sm xl:text-base text-blackLight">
                    Beratung buchen:
                    <br />
                    <a
                        href={bookingUrl || ''}
                        className="underline underline-offset-2"
                    >
                        Zu unserem Calendly
                    </a>
                </span>
            </div>
        </div>
    );
};

export default Navigation;
